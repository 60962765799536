import React, { useState,useEffect } from 'react';
import { Button,Form,Row,Alert } from 'react-bootstrap';
import "./FormPlazoFijo.css"
import calcularFlazoFijo from "./calcularFlazoFijo.js"
import * as yup from "yup";
import Footer from '../Footer/footer.js';
import { format, subDays } from 'date-fns';

function FormPlazoFijo() {
  const [PlazoFijoGanadoTotal, setPlazoFijoGanadoTotal] = useState({"montoInvertido": 0,"diasInvertidos":0});
  const [formData, setFormData] = useState(
    {
      montoInvertido: 0,
      diasInvertidos: 0,
      tna: 0
    });
  const [errors , setErrors] = useState(null);

  const schema = yup.object().shape({
    montoInvertido: yup.string().required("Este campo es obligatorio"),
    diasInvertidos: yup.number().typeError('Debe ser un número').min(30, 'La cantidad de días debe ser entre 30 y 365 dias').max(365, 'La cantidad de días debe ser entre 30 y 365 dias').required("Este campo es obligatorio"),
  });
  
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const handleSubmit = async (e) => { 
    e.preventDefault();
    try {
      await schema.validate(formData, { abortEarly: false });
      console.log("El formulario se envio correctamente");
      console.log(formData)
      setErrors(null); 
      setPlazoFijoGanadoTotal(await calcularFlazoFijo(formData))
      await delay(700);  
      const ElementTnaResult = document.getElementById('totalPagarResultado');
      ElementTnaResult.scrollIntoView({ behavior: 'smooth' });
      // Aquí iría el código para enviar los datos al servidor
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
    }
  }

  const handleChangeNumber = (e) => {
    const soloNumeros = e.target.value.replace(/[^0-9]/g, '');
    setFormData({
      ...formData,
      [e.target.name]: soloNumeros,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleWheel = (e) => {
    e.currentTarget.blur();
  };
  useEffect(() => {
    const getTna = async () => {
      try {

        const today = new Date();
        const date_10_days_ago = subDays(today, 10);
        const today_newformat = format(today, 'yyyy-MM-dd')
        const date_10_days_ago_newformat = format(date_10_days_ago,'yyyy-MM-dd')
        const url = `https://api.bcra.gob.ar/estadisticas/v1/datosvariable/6/${date_10_days_ago_newformat}/${today_newformat}`
        const response = await fetch(url);
        const data = await response.json();
        setFormData({
          ...formData,
          ["tna"]: parseFloat(data["results"][data.results.length - 1]["valor"].replace(",",".")),
        });
      } catch (error) {
        console.error('Error al obtener los datos:', error);
    }}
    getTna()
  },[]);

  return (
    <div>
    <Form className="FormPlazoFijo px-4 py-1" onSubmit={handleSubmit}  >
      <Row className="my-3 " >
        {/* Ingresa el monto a invertir */}
        <Form.Group md="12" controlId="validationdiasInvertidos" className='my-3'>
        <Form.Label className='form_label'>Ingresa el monto a invertir</Form.Label> <span className='leyenda'>*</span>
          <div className='mt-2'>
          <Form.Control
            required
            type="text"
            inputMode="numeric"
            onChange={handleChangeNumber}
            name= "montoInvertido"
            value={formData.montoInvertido }   
            onWheel={handleWheel}
            className= "form_label" 
            min={0}
            onBlur={(e) => {
              handleChange(e);
            }}
          />
          </div>
        </Form.Group>
        {/* Indica la cantidad de dias */}
        <Form.Group md="12" controlId="validationdiasInvertidos" className='my-3'>
        <Form.Label className='form_label'>Indica la cantidad de dias</Form.Label> <span className='leyenda'>*</span>
          <div className='mt-2'>
          <Form.Control
            required
            type="text"
            inputMode="numeric"
            onChange={handleChangeNumber}
            name= "diasInvertidos"
            value={formData.diasInvertidos }   
            onWheel={handleWheel}
            className= "form_label" 
            min={0}
            onBlur={(e) => {
              handleChange(e);
            }}
          />
          </div>          
        </Form.Group>
        {errors && errors.diasInvertidos && <Alert variant="danger">{errors.diasInvertidos}</Alert>}
        </Row>
        <div id="main_button">
        <Button type="submit" className='form_main_button my-3' 
        >Calcular</Button>
        </div>
    </Form>
    {/* BOX RESULTADOS */}
    <div id='totalPagarResultado'>
    <div className='totalPagarResultado my-4' key="PlazoFijoGanadoTotal" variant="PlazoFijoGanadoTotal">
      <div className='box_PlazoFijoGanadoTotal px-3 py-2'>
      <p className='gan_totalPagarEnMano'>Monto total ganado:</p>
      <p className='gan_totalPagarEnMano'>${PlazoFijoGanadoTotal["montoGanadoTotal"]}</p>
      </div>
      <hr className='gan_linea'></hr>
      <div className='box_montoInvertidoMasInfo px-3 py-2'>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>Invertiste: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{PlazoFijoGanadoTotal["invertiste"]} $</p> </div>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>Días invertidos: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{PlazoFijoGanadoTotal["diasInvertidos"]} días </p> </div>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>Monto ganado: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{PlazoFijoGanadoTotal["montoGanado"]} $</p> </div>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>TNA: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{PlazoFijoGanadoTotal["TNA"]} %</p> </div>
      <div className='my-1'><p className='gan_ImpuestoMensual d-inline '>TEA: </p> <p className='gan_ImpuestoMensual d-inline ' style={{color : '#38D800'}}>{PlazoFijoGanadoTotal["TEA"]} %</p> </div>
      </div>
      <Footer />
    </div>
    </div>
      
    </div>
    );
}

export default FormPlazoFijo;
