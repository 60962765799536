import React from 'react';
import "./footer.css"


function Footer() {
    const ENV_TAG_NUMBER = process.env.REACT_APP_TAG_NUMBER ?? 'v00.00.00';
    const year = new Date().getFullYear();

    return (
    <div>
        <div className='box_version'>
            <span className='version'>** TNA según BCRA </span><br></br>
            <span className='version' > Calculadora de Plazo Fijo © {year}- Todos los derechos reservados - {ENV_TAG_NUMBER}</span>
        </div>
    </div>
    );
}

export default Footer;
