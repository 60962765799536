// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main{
    width: 650px;
    margin-top: 2rem;

}
@media screen and (max-width: 780px) {

    #main{
        max-width: 343px;
        margin-top: 1rem;
    }
 }
 `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;;AAEpB;AACA;;IAEI;QACI,gBAAgB;QAChB,gBAAgB;IACpB;CACH","sourcesContent":["#main{\n    width: 650px;\n    margin-top: 2rem;\n\n}\n@media screen and (max-width: 780px) {\n\n    #main{\n        max-width: 343px;\n        margin-top: 1rem;\n    }\n }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
