export async function calcularFlazoFijo(formData){
   let montoGanadoTotal = 0;
   let montoGanado = 0;
   let TNA = formData.tna;
   let TEM = (TNA/100)/365;
   let parteone = (1+(TEM*formData.diasInvertidos));
   let partetwo = (365/formData.diasInvertidos);
   console.log(parteone,partetwo)
   let TEA =((Math.pow(parteone, partetwo)-1)*100);
   console.log(TEA)
   montoGanado = formData.montoInvertido * ((TNA/100) * formData.diasInvertidos / 365)
   montoGanadoTotal = parseInt(formData.montoInvertido) + montoGanado
   return {"montoGanadoTotal" : montoGanadoTotal.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, minimumIntegerDigits: 1 }),"invertiste": formData.montoInvertido, "diasInvertidos": formData.diasInvertidos, "montoGanado": montoGanado.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, minimumIntegerDigits: 1 }), "TNA": TNA, "TEA":TEA.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, minimumIntegerDigits: 1 })}
  }
  

  export default calcularFlazoFijo;