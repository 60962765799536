// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../AdelleSansClarin-VF.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400&family=Inter:wght@400&family=Roboto:wght@400&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Adelle Sans';
    font-style: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');

}

.box_version{
    margin-top: 30px;
    margin-bottom: 10px;
}

.version{
    font-family: 'Adelle Sans';
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width: 1167px) {
    .version{
        font-size: 14px;
    }
 }`, "",{"version":3,"sources":["webpack://./src/Components/Footer/footer.css"],"names":[],"mappings":"AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,4DAA2D;;AAE/D;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;CACH","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400&family=Inter:wght@400&family=Roboto:wght@400&display=swap');\n\n@font-face {\n    font-family: 'Adelle Sans';\n    font-style: normal;\n    src: url('../../AdelleSansClarin-VF.woff2') format('woff2');\n\n}\n\n.box_version{\n    margin-top: 30px;\n    margin-bottom: 10px;\n}\n\n.version{\n    font-family: 'Adelle Sans';\n    font-size: 12px;\n    font-weight: 400;\n}\n\n@media screen and (max-width: 1167px) {\n    .version{\n        font-size: 14px;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
